@font-face {
    font-family: "icons";
    src: url("/assets/fonts/icons/icons.woff?t=1743416189718") format("woff"),
    url('/assets/fonts/icons/icons.ttf?t=1743416189718') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
    url('/assets/fonts/icons/icons.svg?t=1743416189718#icons') format('svg'); /* iOS 4.1- */
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-add-to-cart:before {
    content: "\e900";
}

.icon-apple:before {
    content: "\e901";
}

.icon-ar:before {
    content: "\e902";
}

.icon-arrow-fat-left:before {
    content: "\e903";
}

.icon-arrow-fat-right:before {
    content: "\e904";
}

.icon-arrow-left:before {
    content: "\e905";
}

.icon-arrow-right:before {
    content: "\e906";
}

.icon-calendar:before {
    content: "\e907";
}

.icon-cart:before {
    content: "\e908";
}

.icon-chat:before {
    content: "\e909";
}

.icon-check-circle:before {
    content: "\e90a";
}

.icon-checkmark:before {
    content: "\e90b";
}

.icon-chevron-down:before {
    content: "\e90c";
}

.icon-chevron-left:before {
    content: "\e90d";
}

.icon-chevron-right:before {
    content: "\e90e";
}

.icon-chevron-up:before {
    content: "\e90f";
}

.icon-close-fat:before {
    content: "\e910";
}

.icon-close:before {
    content: "\e911";
}

.icon-color:before {
    content: "\e912";
}

.icon-copy-url:before {
    content: "\e913";
}

.icon-copy:before {
    content: "\e914";
}

.icon-current-location:before {
    content: "\e915";
}

.icon-dealer-shop:before {
    content: "\e916";
}

.icon-dealer:before {
    content: "\e917";
}

.icon-delete:before {
    content: "\e918";
}

.icon-download-question:before {
    content: "\e919";
}

.icon-download:before {
    content: "\e91a";
}

.icon-dropshipment:before {
    content: "\e91b";
}

.icon-error-circle:before {
    content: "\e91c";
}

.icon-events:before {
    content: "\e91d";
}

.icon-experience-center:before {
    content: "\e91e";
}

.icon-facebook:before {
    content: "\e91f";
}

.icon-filter:before {
    content: "\e920";
}

.icon-google-play:before {
    content: "\e921";
}

.icon-hamburger:before {
    content: "\e922";
}

.icon-heart-filled:before {
    content: "\e923";
}

.icon-heart:before {
    content: "\e924";
}

.icon-icon-3d:before {
    content: "\e925";
}

.icon-icon-ar:before {
    content: "\e926";
}

.icon-info:before {
    content: "\e927";
}

.icon-instagram:before {
    content: "\e928";
}

.icon-linkedin:before {
    content: "\e929";
}

.icon-location:before {
    content: "\e92a";
}

.icon-logo:before {
    content: "\e92b";
}

.icon-mail:before {
    content: "\e92c";
}

.icon-news:before {
    content: "\e92d";
}

.icon-no-product:before {
    content: "\e92e";
}

.icon-order:before {
    content: "\e92f";
}

.icon-pause:before {
    content: "\e930";
}

.icon-phone:before {
    content: "\e931";
}

.icon-play:before {
    content: "\e932";
}

.icon-plus:before {
    content: "\e933";
}

.icon-products:before {
    content: "\e934";
}

.icon-profile-review:before {
    content: "\e935";
}

.icon-projects:before {
    content: "\e936";
}

.icon-rearrange:before {
    content: "\e937";
}

.icon-registration:before {
    content: "\e938";
}

.icon-reset:before {
    content: "\e939";
}

.icon-rotate:before {
    content: "\e93a";
}

.icon-round-chevron:before {
    content: "\e93b";
}

.icon-scissors:before {
    content: "\e93c";
}

.icon-scroll-up:before {
    content: "\e93d";
}

.icon-search-fat:before {
    content: "\e93e";
}

.icon-search:before {
    content: "\e93f";
}

.icon-selection:before {
    content: "\e940";
}

.icon-settings:before {
    content: "\e941";
}

.icon-show:before {
    content: "\e942";
}

.icon-sliders:before {
    content: "\e943";
}

.icon-sofa:before {
    content: "\e944";
}

.icon-thin-chevron-down:before {
    content: "\e945";
}

.icon-thin-chevron-left:before {
    content: "\e946";
}

.icon-thin-chevron-right:before {
    content: "\e947";
}

.icon-thin-chevron-up:before {
    content: "\e948";
}

.icon-truck:before {
    content: "\e949";
}

.icon-user:before {
    content: "\e94a";
}

.icon-warning-circle:before {
    content: "\e94b";
}

.icon-webshop:before {
    content: "\e94c";
}

.icon-zoom-plus:before {
    content: "\e94d";
}


